import React from 'react';

const Aboot = () => {
	return (
		<section className='container-main'>
			<h5>Whats this all aboot?</h5>
		</section>
	);
};

export default Aboot;
